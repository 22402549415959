import './App.css';
import Calendar from './Calendar';
import { MuiThemeProvider } from '@material-ui/core'
import ErrorPage from './components/ErrorPage';
import theme from './theme';
import React from "react";

const getUrlParameter = (sParam) => {

    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName;

    if (sURLVariables.length <= 0){
        return '';
    }

    for (let i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1].replace(' ','');
        }
    }
};

const Settings = {
    API_HOST: 'https://api.inbooma.net/api',
    API_HOST_STAGING: 'https://staging-api.inbooma.net/api',
    API_HOST_TEST: 'https://test-api.inbooma.net/api',
    API_HOST_LOCAL: 'http://localhost:8180/api',
    BOOKING_FE_HOST: 'https://general-frontend.inbooma.net/',
    BOOKING_FE_HOST_STAGING: 'https://staging-general-frontend.inbooma.net/',
    BOOKING_FE_HOST_TEST: 'https://test-general-frontend.inbooma.net/',
    WEB_FE_HOST: 'https://web.inbooma.net/',
    WEB_FE_HOST_STAGING: 'https://staging-web.inbooma.net/',
    WEB_FE_HOST_TEST: 'https://test-web.inbooma.net/',
};

const getApiHost = () => {

    let host = Settings.API_HOST;

    if (document.location.host.toLowerCase().indexOf('test-react-cal.inbooma.net') >= 0
        || document.location.host.toLowerCase().indexOf('localhost') >= 0) {
        host = Settings.API_HOST_TEST;
    }

    if (document.location.host.toLowerCase().indexOf('staging-react-cal.inbooma.net') >= 0
        && document.location.host.toLowerCase().indexOf('staging') >= 0) {
        host = Settings.API_HOST_STAGING;
    }
    if(document.location.host.toLowerCase().indexOf('localhost') >= 0) {
        host = Settings.API_HOST_LOCAL;
     }
    return host;
};

const getBookingFEHost = () => {

    let host = Settings.BOOKING_FE_HOST;

    if (document.location.host.toLowerCase().indexOf('test-react-cal.inbooma.net') >= 0
        || document.location.host.toLowerCase().indexOf('localhost') >= 0) {
        host = Settings.BOOKING_FE_HOST_TEST;
    }

    if (document.location.host.toLowerCase().indexOf('staging-react-cal.inbooma.net') >= 0 || document.location.host.toLowerCase().indexOf('staging') >= 0) {
        host = Settings.BOOKING_FE_HOST_STAGING;
    }

    return host;
};

const getWebFEHost = () => {

    let host = Settings.WEB_FE_HOST;

    if (document.location.host.toLowerCase().indexOf('test-react-cal.inbooma.net') >= 0
        || document.location.host.toLowerCase().indexOf('localhost') >= 0) {
        host = Settings.WEB_FE_HOST_TEST;
    }

    if (document.location.host.toLowerCase().indexOf('staging-react-cal.inbooma.net') >= 0 || document.location.host.toLowerCase().indexOf('staging') >= 0) {
        host = Settings.WEB_FE_HOST_STAGING;
    }

    return host;
};

const inArray = (needle, haystack) => {
    let length = haystack.length;
    for(let i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
};

const mapLangToLocalLanguage = (lang) => {
    let newLang = "de_DE";
    if(inArray(lang,['de_DE','en_GB','fr_FR'])){
        newLang = lang;
    }
    if(inArray(lang,['de-DE','en-GB','fr-FR'])){
        let split = lang.split("-");
        newLang = split.join('_');
    }
    if(inArray(lang,['de','en','fr'])){
        switch(lang){
            case 'de':
                newLang = 'de_DE';
                break;
            case 'en':
                newLang = 'en_GB';
                break;
            case 'fr':
                newLang = 'fr_FR';
                break;
            default:
                break;
        }
    }
    let ll = document.documentElement.getAttribute('lang');
    console.log(ll);
    console.log(newLang.substr(0,2));
    if(ll != newLang.substr(0,2)) {
        document.documentElement.setAttribute('lang', newLang.substr(0, 2));
        let ll = document.documentElement.getAttribute('lang');
        console.log(ll);
        let titles = {
            'de': 'Inbooma² WEB | Belegungsplan',
            'en': 'Inbooma² WEB | Occupancy plan',
            'fr': 'Inbooma² WEB | Plan d\'occupation'
        };

        document.documentElement.setAttribute('title', titles[newLang.substr(0, 2)]);

    }
    return newLang;
};

const App = () => {
    let i18n = getUrlParameter('i18n');
    if(typeof i18n === 'undefined' || i18n == null){
        i18n = 'de_DE';
    }
    let language = mapLangToLocalLanguage(i18n);
    let hash = getUrlParameter('hash');
    if(typeof hash === 'undefined' || hash == null){
       return(
           <ErrorPage
        language={language}/>);
    }
    let network = getUrlParameter('network');
    let location_id = getUrlParameter('location_id');
    if(typeof location_id === 'undefined' || location_id == null){
        location_id = null;
    }

    let links = getUrlParameter('links');
    if(typeof links === 'undefined' || links == null){
        links = false;
    } else {
        links = true;
    }
    let withExtraOptions = getUrlParameter('withExtraOptions');
    if(typeof withExtraOptions === 'undefined' || withExtraOptions == null){
        withExtraOptions = false;
    } else {
        withExtraOptions = true;
    }
    let showAlsoNotFEVisible = getUrlParameter('showAlsoNotFEVisible');
    if(typeof showAlsoNotFEVisible === 'undefined' || showAlsoNotFEVisible == null){
        showAlsoNotFEVisible = false;
    } else {
        showAlsoNotFEVisible = true;
    }
    let apiHost = getApiHost();
    let bookingFEHost = getBookingFEHost();
    let split = language.split('_');
    bookingFEHost +="?hash=" + hash + "&i18n.lng=" + split.join('-');
    let webFEHost = getWebFEHost();
    return (
    <MuiThemeProvider theme={theme}>
      <Calendar
         webFEHost={webFEHost}
         withExtraOptions={withExtraOptions}
         showAlsoNotFEVisible={showAlsoNotFEVisible}
         links={links}
         hash={hash}
         apiHost={apiHost}
         language={language}
         bookingFEHost={bookingFEHost}
         network={network}
         location_id={location_id}/>
    </MuiThemeProvider>
  );
}

export default App;
